



















 import { Notify } from "vant"
 import HeadTop from "@/components/ShareComponent/HeadTop.vue"
 import List from "@/components/ShareComponent/PullDownUp.vue"
 import FooterBottom from "@/components/ShareComponent/ListBottom.vue"
 import { Vue,Component } from "vue-property-decorator"
 import ZoomPage from "@/impView/PageSafety";

 interface HandBuyListType<T=string>{
     loadingShow:boolean;
     userId:T|number;
     token:T;
     title:T;
     PullDown:boolean;
     UpDown:boolean;
     StopUp:boolean

     handlePullDown(data?:any):void;
     handleUpDown(data?:any):void;
     handleUpData(bool:boolean):void;
     handleError(data:string):void;
 }

 @Component({ name:"BuyList",components:{ List,FooterBottom,HeadTop } })
 export default class HandBuyList extends ZoomPage implements HandBuyListType{
    loadingShow = false
    userId: string | number = ""
    token = ""
    title = "我出售产品"
    PullDown = false
    UpDown = false
    StopUp = false

    mounted() {
        this.title = this.$route.query.title as string || '我出售产品'
    }

    handlePullDown(data?:any){
        //
    }

    handleUpDown(data?:any){
        //
    }

    handleUpData(bool:boolean){
        //
    }

    handleError( data:string ){
        let str = ""
        if ( data.length ){
            str = data
        }else{
            str = "网络繁忙..."
        }
        Notify({
            message: str,
            color: 'white',
            background: "rgba(0,0,0,.6)",
        });
    }
}
